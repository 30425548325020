import { DateTime } from "luxon"
import Util from "packs/utils/Util"
import { zeroPadding, Logger, funcName } from "../common"
export default class DateTag {
    constructor(
        public days_of_the_week: number[], //[1,2,3]で曜日の繰り返しは持っておく.
        public is_active: boolean,
        public start_hour: number,
        public start_min: number,
        public end_hour: number,
        public end_min: number,
        public id: string,
        public calendar_tags: [],
        public updated_at: number,
        // 最終更新日時（アップデートした際にセルを動的に更新するために利用）
        public systemUpdatedAt: number,
        public select: boolean,
        public text: string,
        public type: string,
        public allday: boolean,
        public use_holiday: boolean
    ) {}

    static fetchFromJson(dateTagsJson: any): DateTag[] {
        let dateTags = []
        const arrOfDateTag = JSON.parse(dateTagsJson)
        Logger(`arrOfDateTag: ${arrOfDateTag.length}`)
        for (let dateTag of arrOfDateTag) {
            const _dateTag: DateTag = dateTag
            let events = DateTag.toWeeklyEvents(_dateTag)
            Logger(`events: ${events}`)
            dateTags.push(_dateTag)
        }
        return dateTags
    }

    static fetchFromAstag(astag: any): DateTag {
        let _dateTag: DateTag = astag
        _dateTag.days_of_the_week = astag.available_days_of_weeks
        _dateTag.updated_at = DateTime.local().toMillis()
        _dateTag.systemUpdatedAt = DateTime.local().toMillis()
        _dateTag.calendar_tags = astag.calendar_tags

        return _dateTag
    }

    static getSelectHours(): number[] {
        // return Array(24)
        //     .fill(0)
        //     .map((_, i) => i)
        return Array(16)
            .fill(0)
            .map((_, i) => i + 8)
    }

    static getSelectMins(): string[] {
        return Array(12)
            .fill(0)
            .map((_, i) => zeroPadding(`${i * 5}`))
    }

    static getTimeStringFromSeconds(sec: number): string {
        let format = DateTime.fromSeconds(sec).toFormat("yyyy年MM月dd日 HH:mm")
        return format
    }

    static getTimeStringSlashFromSeconds(sec: number): string {
        let format = DateTime.fromSeconds(sec).toFormat("MM/dd HH:mm")
        return format
    }

    static toWeeklyEvents(dtag: DateTag) {
        let events = []
        const thisMonday = DateTime.local().startOf("week")
        Logger(`thisMonday: ${thisMonday.toISODate()}`)

        let dateTagId = ""
        if (dtag.id) {
            dateTagId = dtag.id
        } else {
            // リモートに飛ばしていないので、ランダムでIDを作成.（イベントを押下したときに、取得できるよう）
            dateTagId = `${Math.random().toString(32).substring(2)}`
            dtag.id = dateTagId
        }

        for (const inte of dtag.days_of_the_week) {
            Logger(`inte: ${inte}`)
            // inteはSunday起点のため、-1する.(Luxonは月曜起点)
            const currentDay = thisMonday.plus({ days: inte - 1 })
            const currentDate = currentDay.toFormat("yyyy-MM-dd")
            Logger(`currentDay: ${currentDate}`)
            const color = dtag.is_active ? "#52afc7" : "#ccc"

            const dic = {
                start: `${currentDate} ${dtag.start_hour}:${zeroPadding(dtag.start_min)}:00`,
                end: `${currentDate} ${dtag.end_hour}:${zeroPadding(dtag.end_min)}:00`,
                allDay: false,
                color: color,
                days: dtag.days_of_the_week,
                id: dateTagId,
                type: dateTagId,
            }
            Logger(`dic: ${dic.start} ${dic.end}`)
            events.push(dic)
        }
        return events
    }

    static createDefaultDtag(): DateTag {
        return new DateTag(
            [1, 2, 3, 4, 5],
            true,
            8,
            0,
            21,
            0,
            "newId",
            [],
            DateTime.local().toMillis(),
            DateTime.local().toMillis(),
            false,
            "",
            "",
            false,
            false
        )
    }

    static createWeekDayMorning(): DateTag {
        let dtag = this.createDefaultDtag()
        dtag.start_hour = 9
        dtag.end_hour = 12
        dtag.type = "theme"
        return dtag
    }

    static createWeekDayAfternoon(): DateTag {
        let dtag = this.createDefaultDtag()
        dtag.start_hour = 13
        dtag.end_hour = 17
        dtag.type = "theme"
        return dtag
    }

    /**
     * 曜日のarrayを返します。
     */
    static weekNames(): string[] {
        return [`日`, `月`, `火`, `水`, `木`, `金`, `土`]
    }

    static createSelectTimeThemes(adows: DateTag[]): DateTag[] {
        if (Util.isBlank(adows)) {
            adows = []
        }
        const themes = [
            {
                text: "平日午前　　9-12時",
                id: "1",
                days_of_the_week: [1, 2, 3, 4, 5],
                start_hour: 9,
                end_hour: 12,
                select: false,
            },
            {
                text: "平日ランチ　12-13時",
                id: "2",
                days_of_the_week: [1, 2, 3, 4, 5],
                start_hour: 12,
                end_hour: 13,
                select: false,
            },
            {
                text: "平日午後　　13-17時",
                id: "3",
                days_of_the_week: [1, 2, 3, 4, 5],
                start_hour: 13,
                end_hour: 17,
                select: false,
            },
            {
                text: "平日夕方　　17-20時",
                id: "4",
                days_of_the_week: [1, 2, 3, 4, 5],
                start_hour: 17,
                end_hour: 20,
                select: false,
            },
            {
                text: "平日夜　　　20-22時",
                id: "5",
                days_of_the_week: [1, 2, 3, 4, 5],
                start_hour: 20,
                end_hour: 22,
                select: false,
            },
            // {
            //     text: "休日午前　　9-12時",
            //     id: "101",
            //     days_of_the_week: [0, 6],
            //     start_hour: 9,
            //     end_hour: 12,
            //     select: false,
            // },
            // {
            //     text: "休日ランチ　12-13時",
            //     id: "102",
            //     days_of_the_week: [0, 6],
            //     start_hour: 12,
            //     end_hour: 13,
            //     select: false,
            // },
            // {
            //     text: "休日午後　　13-17時",
            //     id: "103",
            //     days_of_the_week: [0, 6],
            //     start_hour: 13,
            //     end_hour: 17,
            //     select: false,
            // },
            // { text: "平日ランチタイムを除いて", id: "6", dotw: [1,2,3,4,5], start_hour: 12, end_hour: 13, select: false },
        ]
        let dtags = []
        for (let theme of themes) {
            let dtag = DateTag.createDefaultDtag()
            // let dtag: DateTag = theme
            dtag.days_of_the_week = theme.days_of_the_week
            dtag.id = theme.id
            dtag.start_hour = theme.start_hour
            dtag.end_hour = theme.end_hour
            dtag.text = theme.text
            dtag.type = "theme"
            let adow = adows.find(ad => {
                if (this.isSameAdow(ad, dtag)) {
                    ad.type = "theme"
                    return ad
                }
            })
            dtag.select = adow ? true : false

            dtag.systemUpdatedAt = DateTime.local().toMillis() + Number(theme.id)
            dtags.push(dtag)
        }
        return dtags
    }

    static deleteFromArray(adows, dtag): DateTag[] {
        let newAdows = adows.filter(ad => {
            if (this.isSameAdow(ad, dtag)) {
            } else {
                return ad
            }
        })
        return newAdows
    }

    // 12:00 - 13:15 -> [12, 12.25, 12.50, 12.75, 13]
    static availableArray(dtag: DateTag): number[] {
        let st = dtag.start_hour + dtag.start_min / 60
        let et = dtag.end_hour + dtag.end_min / 60
        let duration = et - st
        let chunkNum = duration / 0.25
        let arr = Array(chunkNum)
            .fill(0)
            .map((_, i) => {
                return st + i * 0.25
            })
        return arr
    }

    static isSameAdow(ad, dtag): boolean {
        return (
            ad.days_of_the_week.sort().toString() == dtag.days_of_the_week.sort().toString() &&
            ad.start_hour == dtag.start_hour &&
            ad.end_hour == dtag.end_hour &&
            ad.start_min == dtag.start_min &&
            ad.end_min == dtag.end_min
        )
    }

    /**
     * 分を分,時間,日に計算します.
     * @param mins
     * @returns
     */
    static calcurateMins(mins: number = null): [number, string] {
        Logger(`${funcName()} mins:${mins}`)
        if (!mins) return [null, `分`]
        let minutes = mins

        let value = 0
        let unit = ``

        if (minutes % (60 * 24) == 0) {
            // 日
            unit = `日`
            value = minutes / (60 * 24)
        } else if (minutes % 60 == 0) {
            // 時間
            unit = `時間`
            value = minutes / 60
        } else {
            // 分
            unit = `分`
            value = minutes
        }
        Logger(`${funcName()} value:${value}, unit:${unit}`)
        return [value, unit]
    }
}
